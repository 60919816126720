import {getTraceContextCarrier, makeOpenTelemetryWeb} from '@axteams-one/opentelemetry-js-react';
import {appConfig} from './config';

export const openTelemetry = makeOpenTelemetryWeb({
  serviceName: 'data-insights-dashboard-fe',
  serviceVersion: process.env.REACT_APP_DD_VERSION,
  owner: 'owner:bva3',
  environment: appConfig.env,
  collector: appConfig.env === 'prod' ? 'prod' : 'stage',
  traceContextCarrier: getTraceContextCarrier()
});
