import {
  AccordionPanel,
  Text,
  Switch,
  DataGrid,
  DataGridHeader,
  DataGridRow,
  DataGridHeaderCell,
  DataGridBody,
  DataGridCell,
  TableColumnDefinition,
  createTableColumn,
  Spinner,
  tokens,
  makeStyles
} from '@fluentui/react-components';
import {ACAPStatus, CounterInfo, DeviceInfo, ScenarioId} from '../../types';
import useToasts from '../../hooks/useToasts';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useEffect, useState} from 'react';
import {disableReporting, enableReporting, reportingEnabled} from '../../fetcher';
import {scenarioIdString} from '../../helpers/typeConversions';
import {useDataGridStyles} from '../../style/dataGridStyle';
import {TagPicker} from '../../components/TagPicker';
import {PanelEmptyView} from '@axiscommunications/fluent-empty-view';

const useStyles = makeStyles({
  spinner: {
    justifyContent: 'center',
    paddingTop: tokens.spacingHorizontalM
  }
});

interface A2DPCPanelProps {
  readonly device: DeviceInfo;
  readonly enqueueTagRequest: (promise: () => Promise<void>) => void;
}

const A2DPCPanel = ({device, enqueueTagRequest}: A2DPCPanelProps) => {
  const styles = useStyles();
  const gridStyles = useDataGridStyles();
  const {organization} = usePopulatedTopbarValues();
  const {dispatchAppToast} = useToasts();
  const [loading, setLoading] = useState<boolean>(false);
  const [counters, setCounters] = useState<CounterInfo[]>();

  useEffect(() => {
    setCounters(undefined);
    if (!organization?.arn) {
      return;
    }
    reportingEnabled(organization.arn, device.serial).then(resp => {
      if ('error' in resp) {
        dispatchAppToast({
          title: 'Error',
          intent: 'error',
          message: 'Failed to get reporting status'
        });
        return;
      }
      setCounters([
        {
          id: ScenarioId.A2DPCIn,
          reportingEnabled: resp.enabled
        },
        {
          id: ScenarioId.A2DPCOut,
          reportingEnabled: resp.enabled
        }
      ]);
    });
  }, [device.serial, organization?.arn, dispatchAppToast]);

  if (!organization || !counters) {
    return <Spinner className={styles.spinner} />;
  }

  const columns: TableColumnDefinition<CounterInfo>[] = [
    createTableColumn<CounterInfo>({
      renderHeaderCell: () => <Text className={gridStyles.header}>Direction</Text>,
      renderCell: counter => scenarioIdString(counter.id),
      columnId: 'direction'
    }),
    createTableColumn<CounterInfo>({
      renderHeaderCell: () => <Text className={gridStyles.header}>Tags</Text>,
      renderCell: counter => (
        <TagPicker
          enqueueTagRequest={enqueueTagRequest}
          serial={device.serial}
          scenarioId={counter.id}
        />
      ),
      columnId: 'tags'
    }),
    createTableColumn<CounterInfo>({
      renderHeaderCell: () => <Text className={gridStyles.header}>Report</Text>,
      renderCell: counter =>
        loading ? (
          <Spinner className={styles.spinner} size="extra-small" />
        ) : (
          <Switch
            data-testid={counter.id + '-report-switch'}
            disabled={loading}
            checked={counter.reportingEnabled}
            onChange={(_, data) => {
              setLoading(true);
              if (data.checked) {
                enableReporting({
                  organizationId: organization.id,
                  serial: device.serial
                })
                  .then(success => {
                    if (success) {
                      setCounters(counters =>
                        counters?.map(c => {
                          return {...c, reportingEnabled: true};
                        })
                      );
                      dispatchAppToast({
                        title: 'Success',
                        intent: 'success',
                        message: `Successfully enabled reporting`
                      });
                    } else {
                      dispatchAppToast({
                        title: 'Error',
                        intent: 'error',
                        message: 'Failed to enable reporting'
                      });
                    }
                  })
                  .finally(() => setLoading(false));
              } else {
                disableReporting({
                  organizationId: organization.id,
                  serial: device.serial
                })
                  .then(success => {
                    if (success) {
                      setCounters(counters =>
                        counters?.map(c => {
                          return {...c, reportingEnabled: false};
                        })
                      );
                      dispatchAppToast({
                        title: 'Success',
                        intent: 'success',
                        message: `Successfully disabled reporting`
                      });
                    } else {
                      dispatchAppToast({
                        title: 'Error',
                        intent: 'error',
                        message: 'Failed to disable reporting'
                      });
                    }
                  })
                  .finally(() => setLoading(false));
              }
            }}
          />
        ),
      columnId: 'report'
    })
  ];

  return (
    <AccordionPanel>
      {device.acapStatuses.peopleCounter === ACAPStatus.Stopped ? (
        <PanelEmptyView illustration="data" title="Analytics is not enabled">
          Start and configure AXIS People Counter for the device
        </PanelEmptyView>
      ) : (
        <DataGrid
          className={gridStyles.root}
          items={counters || []}
          columns={columns}
          data-testid="counter-table"
          columnSizingOptions={{tags: {defaultWidth: 400}}}
          resizableColumns
        >
          <DataGridHeader>
            <DataGridRow>
              {({renderHeaderCell}) => (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<CounterInfo>>
            {({item, rowId}) => (
              <DataGridRow<CounterInfo> className={gridStyles.row} key={rowId}>
                {({renderCell}) => <DataGridCell>{renderCell(item)}</DataGridCell>}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      )}
    </AccordionPanel>
  );
};

export default A2DPCPanel;
