import {
  Button,
  Card,
  Field,
  makeStyles,
  Subtitle2,
  tokens,
  Input,
  Body1,
  CardFooter,
  Body2,
  ProgressBar,
  Tag as FluentTag,
  TagGroup,
  DialogActions,
  DialogBody,
  DialogTitle,
  Dialog,
  DialogSurface,
  DialogContent,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  MenuPopover,
  MenuTrigger,
  Menu,
  MenuItem
} from '@fluentui/react-components';
import {MoreHorizontal16Filled} from '@fluentui/react-icons';

import PageContent from '../components/PageContent';
import {useCallback, useEffect, useState} from 'react';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {
  createTag as createTagApi,
  deleteTag as deleteTagApi,
  renameTag as renameTagApi
} from '../fetcher';
import NoValidOrganization from '../components/NoValidOrganization';
import PromiseQueue from '../helpers/promiseQueue';
import useToasts from '../hooks/useToasts';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {createTag, deleteTag, renameTag} from '../reducers/tagsSlice';
import {Tag} from '../types';
import Loader from './Loader';

const useStyles = makeStyles({
  newTagButton: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalM,
    alignItems: 'flex-end',
    width: '100%'
  },
  newTagCard: {
    width: '380px'
  },
  progressBarContainer: {
    minHeight: tokens.spacingVerticalXXS
  },
  tagGroup: {
    gap: tokens.spacingHorizontalS,
    flexWrap: 'wrap'
  }
});

enum NewTagStatus {
  EMPTY_TAG = 'Tag must be at least 1 character.',
  TOO_LONG = 'Name cannot be longer than 20 characters.',
  ALREADY_EXISTS = 'This tag already exists.',
  VALID = ''
}

interface NewTag {
  name: string;
  status: NewTagStatus;
}

const tagNameTooLong = (name: string) => name.length > 20;

const tagRequestQueue = new PromiseQueue();

export const TagManagement = () => {
  const styles = useStyles();
  const [newTag, setNewTag] = useState<NewTag | undefined>();
  const {organization, loaded} = usePopulatedTopbarValues();
  const [pendingRequests, setPendingRequests] = useState<number>(0);
  const {dispatchAppToast} = useToasts();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedTag, setEditedTag] = useState<NewTag | undefined>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<Tag | undefined>(undefined);
  const {tags, loading} = useAppSelector(state => state.tagsSlice);
  const dispatch = useAppDispatch();

  const addNewTag = useCallback(() => {
    if (!organization?.arn) return;
    if (!newTag?.name) {
      setNewTag({
        name: '',
        status: NewTagStatus.EMPTY_TAG
      });
      return;
    }
    if (newTag?.status !== NewTagStatus.VALID) {
      return;
    }
    tagRequestQueue.enqueue(
      () =>
        createTagApi({organizationArn: organization.arn, tagName: newTag.name}).then(success => {
          if (!success) {
            dispatchAppToast({
              title: 'Unexpected error',
              intent: 'error',
              message: 'Failed to create tag, please reload the page'
            });
          }
        }),
      setPendingRequests
    );
    dispatch(createTag(newTag.name));
    setNewTag(undefined);
  }, [newTag, setNewTag, dispatchAppToast, organization?.arn, dispatch]);

  const onDeleteTag = useCallback(() => {
    if (!organization?.arn || !selectedTag) return;
    tagRequestQueue.enqueue(
      () =>
        deleteTagApi({organizationArn: organization.arn, tagName: selectedTag.name}).then(
          success => {
            if (!success) {
              dispatchAppToast({
                title: 'Unexpected Error',
                intent: 'error',
                message: 'Failed to delete tag, please reload the page'
              });
            }
          }
        ),
      setPendingRequests
    );
    dispatch(deleteTag(selectedTag.name));
  }, [dispatchAppToast, organization?.arn, selectedTag, dispatch]);

  const onRenameTag = useCallback(() => {
    if (!organization?.arn || !selectedTag) return false;
    if (!editedTag?.name) {
      setEditedTag({
        name: '',
        status: NewTagStatus.EMPTY_TAG
      });
      return false;
    }
    if (editedTag?.status !== NewTagStatus.VALID) {
      return false;
    }
    tagRequestQueue.enqueue(
      () =>
        renameTagApi({
          organizationArn: organization.arn,
          oldTagName: selectedTag.name,
          newTagName: editedTag.name
        }).then(success => {
          if (!success) {
            dispatchAppToast({
              title: 'Unexpected Error',
              intent: 'error',
              message: 'Failed to rename tag, please reload the page'
            });
          }
        }),
      setPendingRequests
    );
    dispatch(renameTag({oldName: selectedTag.name, newName: editedTag.name}));
    return true;
  }, [dispatchAppToast, organization?.arn, selectedTag, editedTag, dispatch]);

  useEffect(() => {
    if (editDialogOpen) {
      setEditedTag(undefined);
    }
  }, [editDialogOpen]);

  if (!organization) {
    return <NoValidOrganization loaded={loaded} />;
  }

  if (loading !== 'succeeded') {
    return <Loader />;
  }

  const tagLimitReached = tags.length >= 100;

  return (
    <PageContent testId="tags-tab" title="Tag management">
      <div className={styles.progressBarContainer} data-testid={'progress-bar-' + pendingRequests}>
        {pendingRequests > 1 && <ProgressBar />}
      </div>
      {tagLimitReached && (
        <MessageBar>
          <MessageBarBody>
            <MessageBarTitle>Tag limit reached</MessageBarTitle>
            Delete an existing tag before creating a new tag.
          </MessageBarBody>
        </MessageBar>
      )}
      <Card size="large" className={styles.newTagCard} data-testid="create-new-tag-card">
        <Subtitle2>Create a new tag</Subtitle2>
        <Body1>Use tags to aggregate and filter data in the dashboard</Body1>
        <Field validationMessage={newTag?.status}>
          <Input
            disabled={tagLimitReached}
            placeholder="New tag"
            maxLength={50}
            value={newTag?.name || ''}
            onChange={(_, data) =>
              setNewTag({
                name: data.value,
                status: tags.find(t => t.name === data.value)
                  ? NewTagStatus.ALREADY_EXISTS
                  : tagNameTooLong(data.value)
                    ? NewTagStatus.TOO_LONG
                    : NewTagStatus.VALID
              })
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                addNewTag();
              }
            }}
          />
        </Field>
        <CardFooter className={styles.newTagButton}>
          <Button
            disabled={tagLimitReached}
            appearance="primary"
            onClick={() => {
              addNewTag();
            }}
          >
            Create
          </Button>
        </CardFooter>
      </Card>
      <Card size="large" data-testid="existing-tags-card">
        <Subtitle2>Existing tags</Subtitle2>
        <Body2>Used tags:</Body2>
        <TagGroup className={styles.tagGroup}>
          {tags
            .filter(t => t.devices.reduce((sum, d) => sum + d.scenarioIds.length, 0) > 0)
            .map(tag => (
              <ExistingTag
                key={tag.name}
                name={tag.name}
                onClickEdit={() => {
                  setSelectedTag(tag);
                  setEditDialogOpen(true);
                }}
                onClickDelete={() => {
                  setSelectedTag(tag);
                  setDeleteDialogOpen(true);
                }}
              />
            ))}
        </TagGroup>
        <Body2>Unused tags:</Body2>
        <TagGroup className={styles.tagGroup}>
          {tags
            .filter(t => t.devices.reduce((sum, d) => sum + d.scenarioIds.length, 0) === 0)
            .map(tag => (
              <ExistingTag
                key={tag.name}
                name={tag.name}
                onClickEdit={() => {
                  setSelectedTag(tag);
                  setEditDialogOpen(true);
                }}
                onClickDelete={() => {
                  setSelectedTag(tag);
                  setDeleteDialogOpen(true);
                }}
              />
            ))}
        </TagGroup>
      </Card>
      <Dialog
        open={editDialogOpen}
        onOpenChange={(event, data) => {
          setEditDialogOpen(data.open);
        }}
      >
        <DialogSurface className={styles.newTagCard}>
          <DialogBody>
            <DialogTitle>{selectedTag?.name}</DialogTitle>
            <DialogContent>
              <Field validationMessage={editedTag?.status}>
                <Input
                  placeholder="New name"
                  maxLength={50}
                  value={editedTag?.name || ''}
                  onChange={(_, data) =>
                    setEditedTag({
                      name: data.value,
                      status: tags.find(t => t.name === data.value)
                        ? NewTagStatus.ALREADY_EXISTS
                        : tagNameTooLong(data.value)
                          ? NewTagStatus.TOO_LONG
                          : NewTagStatus.VALID
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      if (onRenameTag()) {
                        setEditDialogOpen(false);
                      }
                    }
                  }}
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button
                appearance="secondary"
                onClick={() => {
                  setEditDialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                appearance="primary"
                onClick={() => {
                  if (onRenameTag()) {
                    setEditDialogOpen(false);
                  }
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onOpenChange={(event, data) => {
          setDeleteDialogOpen(data.open);
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Delete tag?</DialogTitle>
            <DialogContent>
              {`Are you sure you want to delete the tag from the inventory? Once deleted it will be
            removed from all assigned scenarios (${selectedTag?.devices.reduce((sum, d) => sum + d.scenarioIds.length, 0)} scenarios).`}
            </DialogContent>
            <DialogActions>
              <Button appearance="secondary" onClick={() => setDeleteDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                appearance="primary"
                onClick={() => {
                  onDeleteTag();
                  setDeleteDialogOpen(false);
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </PageContent>
  );
};

interface ExistingTagProps {
  onClickEdit: () => void;
  onClickDelete: () => void;
  name: string;
}

const ExistingTag = ({onClickEdit, onClickDelete, name}: ExistingTagProps) => {
  return (
    <Menu positioning="below-start">
      <MenuTrigger>
        <FluentTag data-testid={'tag-' + name} dismissible dismissIcon={<MoreHorizontal16Filled />}>
          {name}
        </FluentTag>
      </MenuTrigger>
      <MenuPopover>
        <MenuItem onClick={onClickEdit}>Edit</MenuItem>
        <MenuItem onClick={onClickDelete}> Delete</MenuItem>
      </MenuPopover>
    </Menu>
  );
};
