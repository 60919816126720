import {combineReducers} from '@reduxjs/toolkit';

import {devicesSlice} from './devicesSlice';
import {deviceDetailsSlice} from './deviceDetailsSlice';
import {resourceGroupsSlice} from './resourceGroupsSlice';
import {errorsSlice} from './errorsSlice';
import tagsReducer from './tagsSlice';

const rootReducer = combineReducers({
  devicesSlice: devicesSlice.reducer,
  deviceDetailsSlice: deviceDetailsSlice.reducer,
  resourceGroupsSlice: resourceGroupsSlice.reducer,
  errorsSlice: errorsSlice.reducer,
  tagsSlice: tagsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export {rootReducer};
