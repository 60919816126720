import {
  AoAConfiguration,
  GuestTokenPayload,
  DashboardDataPayload,
  ConfigureReportingPayload,
  GetDeviceInfosPayload,
  DeviceInfo,
  CreateTagPayload,
  DeleteTagPayload,
  FetchTagsPayload,
  GetScenarioInfosPayload,
  AoAScenarioInfo,
  AssignTagPayload,
  UnassignTagPayload,
  DeviceStatus,
  RenameTagPayload,
  ResponseWithError,
  GuestTokenResponse,
  DashboardResponse,
  ApiResponseError,
  NullablePartial,
  DevicesResponse,
  BasicDeviceInfo,
  BasicDeviceInfoResponse,
  EnableReportingPayload,
  DisableReportingPayload,
  EventPublicationConfigResponse,
  Tag
} from './types';
import {appConfig} from './config';
import {getAccessToken} from './helpers/getAccessToken';
import {ACAPStatusEnum, nullToUndefined} from './helpers/typeConversions';
import {openTelemetry} from './OpenTelemetryProvider';

const edgelinkFetch = (
  token: string,
  orgArn: string,
  serial: string,
  api: string,
  method: 'GET' | 'POST' = 'GET',
  data?: {apiVersion: string; method: string; params?: object}
): Promise<Response> => {
  const orgId = orgArn.replace('arn:organization:', '');
  return fetch(`${appConfig.edgeLinkURL}/organizations/${orgId}/targets/${serial}/vapix${api}`, {
    method: method,
    headers: {
      ...(method === 'POST' ? {'Content-Type': 'application/json'} : undefined),
      Authorization: 'Bearer ' + token
    },
    body: data ? JSON.stringify(data) : undefined
  }).catch(err => {
    console.log(err);
    return err;
  });
};

export const soapServices = (token: string, orgArn: string, serial: string, xml: string) => {
  return new Promise((resolve, reject) => {
    try {
      const orgId = orgArn.replace('arn:organization:', '');
      const url = `${appConfig.edgeLinkURL}/organizations/${orgId}/targets/${serial}/vapix/vapix/services`;

      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/xml');
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.responseText);
        } else {
          reject(new Error(`SOAP service request failed with status ${xhr.status}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Failed to make SOAP service request'));
      };

      xhr.send(xml);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchImg = (token: string, orgArn: string, serial: string) =>
  edgelinkFetch(
    token,
    orgArn,
    serial,
    '/axis-cgi/jpg/image.cgi?resolution=320x240&compression=25&camera=1'
  ).then(async res => {
    if (res.ok) {
      const imageBlob = await res.blob();
      return URL.createObjectURL(imageBlob);
    }
  });

export const fetchAoaConfig = (token: string, orgArn: string, serial: string) =>
  edgelinkFetch(token, orgArn, serial, '/local/objectanalytics/control.cgi', 'POST', {
    apiVersion: '1.6',
    method: 'getConfiguration'
  }).then(async res => {
    if (res.ok) {
      return res.json();
    }
  });

export const fetchAoaCapabilitites = (token: string, orgArn: string, serial: string) =>
  edgelinkFetch(token, orgArn, serial, '/local/objectanalytics/control.cgi', 'POST', {
    apiVersion: '1.6',
    method: 'getConfigurationCapabilities'
  }).then(async res => {
    if (res.ok) {
      return res.json();
    }
  });

export const setNewAoaConfig = (
  token: string,
  orgArn: string,
  serial: string,
  config: AoAConfiguration
) =>
  edgelinkFetch(token, orgArn, serial, '/local/objectanalytics/control.cgi', 'POST', {
    apiVersion: '1.6',
    method: 'setConfiguration',
    params: config
  }).then(async res => {
    if (res.ok) {
      return res.json();
    }
  });

export const fetchGuestToken = (
  payload: GuestTokenPayload
): Promise<ResponseWithError<GuestTokenResponse>> =>
  dashboardApiFetch('guest_token', 'POST', payload)
    .then(async resp => {
      if (resp.ok) {
        const respJson = (await resp.json()) as NullablePartial<GuestTokenResponse>;
        if (respJson.guestToken === null) {
          return {error: ApiResponseError.NO_DEVICES};
        }
        return {guestToken: respJson.guestToken};
      }
      throw new Error();
    })
    .catch(() => {
      return {error: ApiResponseError.UNKNOWN};
    });

export const fetchDashboardData = (
  payload: DashboardDataPayload
): Promise<ResponseWithError<DashboardResponse>> =>
  dashboardApiFetch('dashboards', 'GET', undefined, {
    organizationArn: payload.organizationArn,
    resourceGroupId: payload.resourceGroupId
  })
    .then(rsp => {
      if (rsp.ok) {
        return rsp.json();
      }
      throw new Error();
    })
    .catch(() => {
      return {error: ApiResponseError.UNKNOWN};
    });

// For individual AOA scenarios
export const configureReporting = (payload: ConfigureReportingPayload) =>
  dashboardApiFetch('configure_reporting', 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(() => {
      return false;
    });

// For the data aggregator ACAP events
export const enableReporting = (payload: EnableReportingPayload) =>
  dashboardApiFetch('enable_reporting', 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(() => {
      return false;
    });

// For the data aggregator ACAP events
export const disableReporting = (payload: DisableReportingPayload) =>
  dashboardApiFetch('disable_reporting', 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(() => {
      return false;
    });

export const getDeviceInfos = (
  payload: GetDeviceInfosPayload
): Promise<ResponseWithError<DeviceInfo[]>> =>
  dashboardApiFetch('devices', 'GET', undefined, {
    organization: payload.organizationArn
  })
    .then(async resp => {
      if (resp.ok) {
        const respJson = (await resp.json()) as DevicesResponse;
        return respJson.devices.map(d => {
          return {
            arn: d.arn,
            iotCoreConnected: d.iotCoreConnected,
            model: nullToUndefined(d.model),
            piaItemId: nullToUndefined(d.piaItemId),
            serial: d.serial,
            name: nullToUndefined(d.name),
            acapStatuses: {
              aoa: ACAPStatusEnum(d.acapStatuses.aoa),
              aggregator: ACAPStatusEnum(d.acapStatuses.aggregator),
              peopleCounter: ACAPStatusEnum(d.acapStatuses.peopleCounter),
              imageHealth: ACAPStatusEnum(d.acapStatuses.imageHealth)
            }
          };
        });
      }
      throw new Error(resp.status.toString());
    })
    .catch(err => {
      console.log(err);
      return {error: ApiResponseError.UNKNOWN};
    });

export const getScenarioInfos = (
  payload: GetScenarioInfosPayload
): Promise<ResponseWithError<AoAScenarioInfo[]>> =>
  dashboardApiFetch('scenarios', 'GET', undefined, {
    serial: payload.serial,
    organizationId: payload.organizationId
  })
    .then(res => {
      if (res.ok) {
        return res.json() as Promise<AoAScenarioInfo[]>;
      }
      throw new Error(res.status.toString());
    })
    .catch(err => {
      console.log(err);
      return {error: ApiResponseError.UNKNOWN};
    });

export const createTag = (payload: CreateTagPayload): Promise<boolean> =>
  dashboardApiFetch('tag', 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(() => false);

export const deleteTag = (payload: DeleteTagPayload) =>
  dashboardApiFetch(`tag/${payload.tagName}`, 'DELETE', undefined, {
    organizationArn: payload.organizationArn
  })
    .then(rsp => rsp.ok)
    .catch(() => false);

export const fetchTags = (payload: FetchTagsPayload): Promise<ResponseWithError<Tag[]>> =>
  dashboardApiFetch('tag', 'GET', undefined, {
    organizationArn: payload.organizationArn
  })
    .then(rsp => {
      if (rsp.ok) {
        return rsp.json();
      }
      throw new Error(rsp.status.toString());
    })
    .catch(err => {
      console.log(err);
      return {error: ApiResponseError.UNKNOWN};
    });

export const assignTag = (payload: AssignTagPayload): Promise<boolean> =>
  dashboardApiFetch('assign_tag', 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(() => false);

export const unassignTag = (payload: UnassignTagPayload): Promise<boolean> =>
  dashboardApiFetch('unassign_tag', 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(() => false);

export const renameTag = (payload: RenameTagPayload): Promise<boolean> =>
  dashboardApiFetch('rename_tag', 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(() => false);

const dashboardApiFetch = (
  apiName: string,
  method: 'GET' | 'POST' | 'DELETE',
  payload?: object,
  searchParam?: Record<string, string>
): Promise<Response> =>
  openTelemetry.runInClientTraceSpan(`${method} ${apiName.split('/')[0]}`, ({traceSpan}) =>
    getAccessToken().then(token =>
      fetch(
        `${appConfig.dashboardApiURL}/api/${apiName}${searchParam ? `?${new URLSearchParams(searchParam)}` : ''}`,
        {
          method: method,
          headers: {
            ...traceSpan.getTraceContextCarrier(),
            ...(method === 'POST' ? {'Content-Type': 'application/json'} : undefined),
            Authorization: 'Bearer ' + token
          },
          body: JSON.stringify(payload)
        }
      )
        .then(rsp => {
          if (rsp.ok) {
            traceSpan.ok();
            return rsp;
          }
          traceSpan.error(rsp.status);
          return rsp;
        })
        .catch(err => {
          traceSpan.error(err);
          console.error(err);
          throw Error(err);
        })
    )
  );

export const getBasicDeviceInfo = (orgArn: string, serial: string): Promise<BasicDeviceInfo> =>
  openTelemetry.runInClientTraceSpan('POST basicdeviceinfo', ({traceSpan}) =>
    getAccessToken().then(token =>
      edgelinkFetch(token, orgArn, serial, '/axis-cgi/basicdeviceinfo.cgi', 'POST', {
        apiVersion: '1.0',
        method: 'getAllProperties'
      })
        .then(async rsp => {
          if (rsp.status === 200) {
            traceSpan.ok();
            const rspJson = (await rsp.json()) as BasicDeviceInfoResponse;
            return {
              status: DeviceStatus.Reachable as const,
              model: rspJson.data.propertyList.ProdShortName
            };
          }
          if (rsp.status === 404) {
            traceSpan.ok();
            return {
              status: DeviceStatus.Unreachable as const
            };
          }
          if (rsp.status === 403) {
            traceSpan.ok();
            return {status: DeviceStatus.Forbidden as const};
          }
          traceSpan.error({
            status: rsp.status,
            text: rsp.text()
          });
          return {status: DeviceStatus.Unreachable as const};
        })
        .catch(err => {
          console.error(err);
          traceSpan.error(err);
          return {status: DeviceStatus.Unreachable};
        })
    )
  );

export const reportingEnabled = (
  orgArn: string,
  serial: string
): Promise<ResponseWithError<{enabled: boolean}>> =>
  openTelemetry.runInClientTraceSpan('POST getEventPublicationConfig', ({traceSpan}) =>
    getAccessToken().then(token =>
      edgelinkFetch(token, orgArn, serial, '/axis-cgi/mqtt/event.cgi', 'POST', {
        apiVersion: '1.0',
        method: 'getEventPublicationConfig'
      })
        .then(async rsp => {
          if (rsp.status === 200) {
            traceSpan.ok();
            const rspJson = (await rsp.json()) as EventPublicationConfigResponse;
            const found = rspJson.data.eventPublicationConfig.eventFilterList.find(conf => {
              return conf.topicFilter === 'axis:CameraApplicationPlatform/AxisDataAggregator';
            });
            if (found) {
              return {enabled: true};
            }
            return {enabled: false};
          }
          throw new Error(rsp.status.toString());
        })
        .catch(err => {
          console.log(err);
          return {error: ApiResponseError.UNKNOWN};
        })
    )
  );
